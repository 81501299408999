import React, { Component } from "react";
import "./Quiz.css";
import Chat from "./Chat";

class Quiz extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="col-sm-3 col-md-12  stheader containerQuiz">
          <img
            src="https://h8.events.novartis.fr/DIF/images/header-page-accueil-quiz-novartis.png"
            width="100%"
          />

          <div className="fond">
            <div>
              <p>
                Cliquez sur le bouton ci-dessous pour répondre aux questions
              </p>
            </div>
            <a
              href="https://h8.events.novartis.fr/QUIZ/DIC2023/question/#/pleniere"
              target="_blank"
              className="button"
            >
              {" "}
              Plénière
            </a>
          </div>
        </div>
        <Chat />
      </div>
    );
  }
}

export default Quiz;
